import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/MenuWeb.vue'),
    children: [
        {
            path: '/',
            name: 'Tattoos',
            component: () => import('@/views/Tattoos/Tattoos.vue'),
        },
        {
            path: 'tattoos/tattoo-detalhe',
            name: 'TattooDetalhe',
            component: () => import('@/views/Tattoos/TattooDetalhe.vue'),
        },
        {
            path: '/equipe',
            name: 'Equipe',
            component: () => import('@/views/Equipe/Equipe.vue'),
        },
        {
            path: '/estudio',
            name: 'Estudio',
            component: () => import('@/views/Estudio/Estudio.vue'),
        },
        {
            path: '/reserva',
            name: 'Reserva',
            component: () => import('@/views/Reserva/Reserva.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'reserva/reserva-confirma',
            name: 'ReservaConfirma',
            component: () => import('@/views/Reserva/ReservaConfirma.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'reserva/reserva-finalizada',
            name: 'ReservaFinalizada',
            component: () => import('@/views/Reserva/ReservaFinalizada.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'configuracoes',
            name: 'Configuracoes',
            component: () => import('@/views/Configuracoes/Configuracoes.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'configuracoes/reserva/reserva-configuracao',
            name: 'ReservasConfiguracao',
            component: () => import('@/views/Configuracoes/ReservasConfiguracao.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'configuracoes/reserva/:id/reserva-detalhes',
            name: 'ReservaDetalhes',
            component: () => import('@/views/Configuracoes/ReservaDetalhes.vue'),
            meta: {
                requiresAuth: true
            }
        }
    ]
  },
  {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: () => import('@/views/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  try {
    const currentUser = getAuth()
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    
    onAuthStateChanged(currentUser, (user) => {
        if (requiresAuth && !user) next({ name: 'Login' });
        else if (!requiresAuth && user) next();
        else next();
    })
            
  }catch(error) {
    console.log(error)
    next('/')
  }
})


export default router
